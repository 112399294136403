import React from "react";
import "../config/i18n";
import { Link } from 'gatsby';
import { useTranslation } from "react-i18next";
import PageWrapper from "../components/PageWrapper";

const CareerDetails = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <><Link to="https://profile.thedigi.app">
            <button className="btn btn btn-dark-digi btn-sm-2 rounded-8 h-50 font-size-5">
            <i className="fa fa-lock mr-9 font-size-5 " />   {t("login")}  
          </button>
          </Link>
            </>
          ),
          footerStyle: "style6",
        }}
      >
        <main className="pt-23 pt-md-27 pb-13 pb-md-19 pt-lg-29 pb-lg-27">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-11">
                {/* job-details-content */}
                <div className="text-center pb-0">
                  {/* job-top-header */}
                  <div className="mb-7 d-inline-flex align-items-center flex-wrap">
                    <span className="d-block d-inline-flex align-items-center mr-sm-6 text-bali-gray">
                      <i className="icon icon-pin-3 mr-1 text-bali-gray position-relative" />
                      {t("careers_details_subtitle1")}
                    </span>
                    <p className="bg-dark-green-op1 font-size-3 font-weight-medium badge mb-0 py-2 px-4 ml-8">
                      <span className="text-dark-green">
                      {t("careers_details_subtitle2")}
                      </span>
                    </p>
                  </div>
                  <h2 className="font-size-11 font-weight-bold text-center mb-lg-15 mb-0">
                  {t("careers_details_title")}
                  </h2>
                </div>
              </div>
              <div className="col-xl-9 col-lg-10 px-xl-0 px-lg-6 px-md-0 px-6 pr-0">
                {/* terms-contents  */}
                <div className="pt-lg-0 pt-10 pl-lg-10 px-xl-15">
                  <p className="font-size-5 heading-default-color mb-9">
                  {t("careers_details_paragraph1")}
                   </p>                  
                  <div className="article-block mt-11 mt-lg-15">
                    <h5 className="font-size-7 mb-6">
                    {t("careers_details_heading1")}
                    </h5>
                    <p className="font-size-5 heading-default-color mb-9">
                    {t("careers_details_paragraph2")}
                    </p>
                    <p className="font-size-5 heading-default-color mb-9">
                    {t("careers_details_paragraph3")}
                    </p>
                    <p className="font-size-5 heading-default-color mb-9">
                    {t("careers_details_paragraph4")}
                      </p>
                  </div>
                  {/* article-block */}
                  <div className="mt-11 mt-lg-16">
                    {/* block-title */}
                    <h5 className="font-size-7 mb-6">
                    {t("careers_details_heading2")}
                    </h5>
                    {/* term-list */}
                    <ul className="p-0 m-0 list-disc">
                      <li className="position-relative font-size-5 heading-default-color pl-8 d-flex mb-7">
                        <span className="circle-9 mr-3 mt-4 bg-black" />
                        {t("careers_details_bullet1")}
                      </li>
                      <li className="position-relative font-size-5 heading-default-color pl-8 d-flex mb-7">
                        <span className="circle-9 mr-3 mt-4 bg-black" />
                        {t("careers_details_bullet2")}
                      </li>
                      <li className="position-relative font-size-5 heading-default-color pl-8 d-flex mb-7">
                        <span className="circle-9 mr-3 mt-4 bg-black" />
                        {t("careers_details_bullet3")}
                      </li>
                      <li className="position-relative font-size-5 heading-default-color pl-8 d-flex mb-7">
                        <span className="circle-9 mr-3 mt-4 bg-black" />
                        {t("careers_details_bullet4")}
                      </li>
                      <li className="position-relative font-size-5 heading-default-color pl-8 d-flex mb-7">
                        <span className="circle-9 mr-3 mt-4 bg-black" />
                        {t("careers_details_bullet5")}
                      </li>
                      <li className="position-relative font-size-5 heading-default-color pl-8 d-flex mb-7">
                        <span className="circle-9 mr-3 mt-4 bg-black" />
                        {t("careers_details_bullet6")}
                      </li>
                      <li className="position-relative font-size-5 heading-default-color pl-8 d-flex mb-7">
                        <span className="circle-9 mr-3 mt-4 bg-black" />
                        {t("careers_details_bullet7")}
                      </li>
                      <li className="position-relative font-size-5 heading-default-color pl-8 d-flex mb-7">
                        <span className="circle-9 mr-3 mt-4 bg-black" />
                        {t("careers_details_bullet8")}
                      </li>
                    </ul>
                  </div>
                  {/* article-block */}
                  <div className="mt-11 mt-md-16">
                    <h5 className="font-size-7 mb-7">
                    {t("careers_details_heading3")}
                    </h5>
                    {t("careers_details_paragraph5")}<br /><a href="mailto:careers@thedigi.app"><b>careers@thedigi.app</b></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </PageWrapper>
    </>
  );
};
export default CareerDetails;
